import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import '@/assets/themes/globals.css';
import '@/assets/themes/dark-theme.css';
import '@/assets/themes/dark.css';
import '@/assets/themes/default.css';
import '@/assets/themes/Home.module.css';
import { API } from './api';

export const app = createApp(App);

app.config.globalProperties.api = new API();

app.use(router);

app.use(Antd);

app.mount('#app');