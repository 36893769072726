export class API {
    constructor() {
        this.baseUrl = '/api';
        this.username = '';
        this.company = '';
        this.role = '';
        this.email = '';
        this.token = localStorage.getItem('token');
        this.countries = [];
        this.products = [];
        this.catalogTypes = []; 

        this.fetchCountries();

        if (this.token) {
            let splitted = this.token.split('.');
            let jObj = atob(splitted[1].replace(/_/g, '/').replace(/-/g, '+'));
            let claims = JSON.parse(jObj);

            const unixTime = Math.floor(Date.now() / 1000);

            if (claims['exp'] < unixTime) {
                localStorage.removeItem('token');
                window.location.reload();
            } else {
                this.company = claims['company']
                this.role = claims['role'];
                this.username = claims['name'];
                this.email = claims['email'];
                this.fetchProducts();
            }
        }
    }

    async postJsonNoAuth(path, body) {
        return await fetch(`${this.baseUrl}${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    async httpSend(path, method, body) {
        return await fetch(`${this.baseUrl}${path}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            },
            body: JSON.stringify(body)
        });
    }

    async postJson(path, body) {
        return await this.httpSend(path, 'POST', body);
    }

    async putJson(path, body) {
        return await this.httpSend(path, 'PUT', body);
    }

    async deleteJson(path, body) {
        return await this.httpSend(path, 'DELETE', body);
    }

    async getJson(path) {
        return await fetch(`${this.baseUrl}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        });
    }

    async fetchCountries() {
        let resp = await fetch(`${this.baseUrl}/countries`);
        let countries = await resp.json();
        this.countries = countries.map(e => {
            return {
                'value': e,
                'label': e
            }
        });
    }

    async doFirstLogin(email, password, loginToken) {
        let r = await this.postJsonNoAuth(`/auth/first-login`, {
            'email': email,
            'password': password,
            'login_token': loginToken
        });
        if (r.status === 200) {
            let secret = await r.json();
            return secret['2fa_secret'];
        } else {
            return null;
        }
    }
    
    async changePassword(email, password, loginToken) {
        let r = await this.postJsonNoAuth(`/auth/password-change`, {
            'email': email,
            'password': password,
            'login_token': loginToken
        });
        return r.status === 200;
    }
    
    async checkCredentials(email, password) {
        let r = await this.postJsonNoAuth(`/auth/login`, {
            'email': email,
            'password': password
        });
        return r.status === 200;
    }

    async login2fa(email, password, authCode) {
        let r = await this.postJsonNoAuth(`/auth/2fa`, {
            'email': email,
            'password': password,
            'auth_code': authCode
        });
        if (r.status === 200) {
            let login = await r.json();
            localStorage.setItem('token', login['session']);
            this.token = login['session'];
            this.username = login['name'];
            this.company = login['user_company_name'];
            this.role = login['role'];
            let splitted = this.token.split('.');
            let jObj = atob(splitted[1].replace(/_/g, '/').replace(/-/g, '+'));
            let claims = JSON.parse(jObj);
            this.email = claims['email']
            this.fetchProducts();
            return true;
        } else {
            return null;
        }
    }

    async requestPasswordChange(email) {
        let r = await this.postJsonNoAuth(`/auth/password-change-request`, {
            'email': email
        });
        return r.status === 200;
    }

    async generateNew2faCode() {
        let r = await this.postJson(`/auth/2fa/new`, {});
        if (r.status === 200) {
            let secretObj = await r.json();
            return secretObj['secret'];
        }
    }

    async createNewDealRegistration(company, vat, country, referent, email, catalogType, endUserCompany, endUserVAT, products) {
        let r = await this.postJson(`/deal-registrations/new`, {
            'company': company,
            'vat': vat,
            'country': country,
            'referent': referent,
            'email': email,
            'priceListType': catalogType,
            'products': products,
            'endUserCompany': endUserCompany,
            'endUserVAT': endUserVAT
        });
        return r.status; 
    }

    async updateDealRegistration(id, company, vat, country, referent, email, catalogType, endUserCompany, endUserVAT, products) {
        let r = await this.putJson(`/deal-registrations/edit`, {
            'id': id,
            'company': company,
            'vat': vat,
            'country': country,
            'referent': referent,
            'email': email,
            'priceListType': catalogType,
            'products': products,
            'endUserCompany': endUserCompany,
            'endUserVAT': endUserVAT
        });
        return r.status; 
    }

    async getDealRegistrations() {
        let r = await this.getJson(`/deal-registrations`);
        return await r.json() 
    }

    async queryDealRegistrations(q) {
        let r = await this.getJson(`/deal-registrations/search?q=${encodeURIComponent(q)}`);
        return await r.json() 
    }

    async getMyDealRegistrations() {
        let r = await this.getJson(`/deal-registrations/mine`);
        return await r.json();
    }

    async queryMyDealRegistrations(q) {
        let r = await this.getJson(`/deal-registrations/mine/search?q=${encodeURIComponent(q)}`);
        return await r.json() 
    }

    async setStateDealRegistration(id, state) {
        let r = await this.postJson(`/deal-registrations/set-state`, {
            'id': id,
            'state': state
        });
        return r.status === 200; 
    }

    async deleteDealRegistration(id) {
        let r = await this.deleteJson(`/deal-registrations/delete`, {
            'id': id
        });
        return r.status === 200; 
    }

    async createNewVAD(email, name, vat, country, referent) {
        let r = await this.postJson(`/vads/new`, {
            'email': email,
            'vad_name': name,
            'vat': vat,
            'country': country,
            'referent': referent
        });
        return r.status === 201;
    }
    
    async getVADs() {
        let r = await this.getJson(`/vads`);
        return await r.json();
    }

    async editVAD(id, email, name, vat, country, referent, active) {
        let r = await this.putJson(`/vads/edit`, {
            'id': id,
            'email': email,
            'vad_name': name,
            'vat': vat,
            'country': country,
            'referent': referent,
            'active': (active ? 1 : 0)
        });
        return r.status === 200;
    }

    async deleteVAD(id) {
        let r = await this.deleteJson(`/vads/delete`, {
            'id': id
        });
        return r.status === 200;
    }

    async getAdmins() {
        let r = await this.getJson(`/admin-users`);
        return await r.json();
    }

    async createAdmin(email) {
        let r = await this.postJson(`/admin-users/new`, {
            'email': email
        });
        return r.status === 201;
    }

    async editAdmin(id, active) {
        let r = await this.putJson(`/admin-users/edit`, {
            'id': id,
            'active': (active ? 1 : 0)
        });
        return r.status === 200;
    }

    async deleteAdmin(id) {
        let r = await this.deleteJson(`/admin-users/delete`, {
            'id': id
        });
        return r.status === 200;
    }

    async fetchProducts() {
        let r = await this.getJson(`/deal-registrations/products`);
        let resp = await r.json();
        this.products = resp['products'];
        this.catalogTypes = resp['catalogs'].map(e => { return { 'value': e, 'label': e } });
    }
}